* {
	margin: 0;
}

ul, li, a {
	text-decoration: none;
	color: inherit;
}

button {
	cursor: pointer;
}

input {
	font-family: inherit;
}